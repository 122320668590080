import { Component, ElementRef, Input } from '@angular/core';

interface TimeSpan {
    startDate: Date | null;
    endDate: Date | null;
    isNightShift: boolean;

    left: number;
    width: number;
}

interface PositionStyle {
    width: `${number}%`;
    left: `${number}%`;
}

const LABEL_SPACING = 50; // px
const MINUTES_IN_DAY = 1440;

@Component({
    selector: 'app-timeframe-display',
    templateUrl: './timeframe-display.component.html',
    styleUrl: './timeframe-display.component.scss',
})
export class TimeframeDisplayComponent {
    protected readonly timeSpan: TimeSpan = {
        startDate: null,
        endDate: null,
        isNightShift: false,
        left: 0,
        width: 0,
    };

    protected position: PositionStyle = {
        left: '0%',
        width: '100%',
    };

    @Input()
    public set end(value: Date | null) {
        this.timeSpan.endDate = this.validateDate(value, 'end');
        this.calculateTimeSpan();
    }

    @Input()
    public set start(value: Date | null) {
        this.timeSpan.startDate = this.validateDate(value, 'start');
        this.calculateTimeSpan();
    }

    public constructor(private readonly hostElement: ElementRef) {}

    private getLabelSpacingPct(): number {
        const barWidth = this.hostElement.nativeElement.offsetWidth;
        return (LABEL_SPACING / barWidth) * 100;
    }

    private calculateTimeSpan(): void {
        const ts = this.timeSpan;

        // If the start date is after the end date, it's a night shift.
        ts.isNightShift = ts.startDate && ts.endDate && ts.startDate > ts.endDate;

        ts.isNightShift ? this.positionForNightShift() : this.positionForDayShift();

        // Re-assign the position object to trigger Angular change detection.
        this.position = {
            left: `${ts.left}%`,
            width: `${ts.width}%`,
        };
    }

    private positionForDayShift(): void {
        const ts = this.timeSpan;
        const spacingPct = this.getLabelSpacingPct();

        let start = 0;
        if (ts.startDate) {
            start = this.dateToPercentage(ts.startDate);
        }

        let width = 100 - start;
        if (ts.endDate) {
            width = this.dateToPercentage(ts.endDate) - start;
        }

        // Ensure that the time span is not positioned outside of the container.
        // If the time span is too close to the right edge, move it to the left.
        ts.left = Math.min(start, 100 - spacingPct * 1.5);
        ts.width = Math.max(width, spacingPct);

        // If the start and end are too close to each other, move the start to the left if the end is before noon and
        // there's space, or increase the width if the end is after noon.
        if (width < spacingPct) {
            if (ts.left + width < 50 && ts.left > spacingPct + width) {
                ts.left -= spacingPct - width;
            } else {
                ts.width = spacingPct;
            }
        }
    }

    private positionForNightShift(): void {
        const ts = this.timeSpan;
        const spacingPct = this.getLabelSpacingPct();

        let start = 0;
        if (ts.startDate) {
            start = this.dateToPercentage(ts.startDate);
        }

        let width = 100 - start;
        if (ts.endDate) {
            width = this.dateToPercentage(ts.endDate);
        }

        // If these 2 labels are too close to each other
        if (start - width < spacingPct) {
            // If we're in the left half of the timeline, shorten the left bar.
            // Otherwise, shorten the right bar.
            if ((start + width) / 2 < 50) {
                start += spacingPct;
            } else {
                width -= spacingPct;
            }
        }

        ts.left = start;
        ts.width = width;
    }

    private dateToPercentage(value: Date): number {
        const paddingPct = this.getLabelSpacingPct() / 2;

        let position = (value.getHours() * 60 + value.getMinutes()) / (MINUTES_IN_DAY / 100);

        // Make sure the label doesn't go overflow the bar width.
        position = Math.min(position, 100 - paddingPct);
        return Math.max(position, paddingPct);
    }

    private validateDate(date: Date | null, field: 'start' | 'end'): Date | null {
        if (date === null || (date instanceof Date && !isNaN(date.getTime()))) {
            return date;
        }

        console.error(`Invalid "${field}" date passed into "timeframe-display" component`);
        return null;
    }
}
